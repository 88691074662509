import { Footer, Hero } from '@home-page-components';
import { AdsDisclaimer, Seo } from '@bluefinenterprises/bluefin-libs';
import { popularCompaniesJSON } from '@data';
import { ArrowNarrowRightIcon, SpeakerphoneIcon } from '@heroicons/react/outline';
import {
  AcademicCapIcon,
  ArchiveIcon,
  BeakerIcon,
} from '@heroicons/react/solid';
import { ElementType, ReactChild, ReactChildren } from 'react';
import {
  BaseCta,
  ClickableWrapper,
  Logo,
  PopularCategoriesCard,
  SolidCta,
} from '@new-components';
import Head from 'next/head';
import { APP_URL } from '@backend';
import { VerticalData } from '@types';

export function BackgroundPattern({ className }: { className: string }) {
  return (
    <svg
      className={className}
      viewBox='0 0 200 200'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M40.9 53.825C80.61 3 95.347 1.19 146.174 40.9S198.81 95.348 159.1 146.175 104.652 198.81 53.825 159.1 1.19 104.652 40.9 53.825'
        fill='currentColor'
      />
    </svg>
  );
}

type CardProps = {
  data: string;
  label: string;
  Icon: ElementType;
};

function StateCard({ data, label, Icon }: CardProps) {
  return (
    <div className='flex flex-col border-t border-gray-100 gap-y-3 items-center p-6 text-center sm:border-0 sm:border-l'>
      <Icon className='w-10 h-10 md:w-12 md:h-12 text-gray-500' />
      <dd className='text-3xl md:text-5xl font-extrabold text-blue-600'>{data}</dd>
      <dt className='text-lg leading-6 font-medium text-gray-500'>
        {label}
      </dt>
    </div>
  );
}

function SectionHeading({ children }: { children: ReactChild | ReactChild[] | ReactChildren }) {
  return (
    <h2 className='text-center text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl my-8'>
      {children}
    </h2>
  );
}

export default function HomePage({ verticals }: { verticals: VerticalData[] }) {
  const companies = popularCompaniesJSON.map((company) => ({
    ...company,
    imageUrl: `/home-page/companies/popular/${company.imageUrl}`,
  }));

  const states = [
    { data: '200+', label: 'Comparison Categories', Icon: ArchiveIcon },
    { data: '3,000+', label: 'Products Tested', Icon: BeakerIcon },
    { data: '5M+', label: 'Decisions Simplified', Icon: AcademicCapIcon },
  ];

  // Sort verticals by the length of their categories in descending order
  const sortedVerticals = verticals.slice().sort((a, b) => b.categories.length - a.categories.length);
  return (
    <>
      <Head>
        <title>Comparison Engine - Comparison411.com</title>
        <Seo
          title='Comparison Engine - Comparison411.com'
          description='Comparison411 makes decisions easier for millions of users by narrowing options to the top review lists from the best brands available. We research and test thousands of products to create top lists for each category.'
          imageUrl='/home-page/hero.jpg'
          pageUrl={APP_URL}
        />
        <link rel='stylesheet' href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,1,0' />
        <script type='text/javascript'>
          {`var _iub = _iub || [];
          _iub.csConfiguration = {
            "askConsentAtCookiePolicyUpdate": true,
            "enableUspr": true,
            "floatingPreferencesButtonDisplay": "bottom-right",
            "lang": "en",
            "perPurposeConsent": true,
            "siteId": 3610835,
            "usprApplies": true,
            "whitelabel": false,
            "cookiePolicyId": 69172241,
            "banner": {
              "acceptButtonDisplay": true,
              "closeButtonDisplay": false,
              "customizeButtonDisplay": true,
              "explicitWithdrawal": true,
              "listPurposes": true,
              "position": "float-bottom-left",
              "rejectButtonDisplay": true,
              "showTitle": false
            }
          };`}
        </script>
        <meta name='ahrefs-site-verification' content='bedc49f7441b3fa770f1c05d6969970652c9c8ce8f847815b7e2db29e356821e' />
      </Head>

      <div className='disclosure home'>
        <AdsDisclaimer text="The placement of companies on Comparison411.com's page is impacted by the compensation received from those companies." longText='This Affiliate Compensation Disclosure is provided by Comparison411.com and its associated entities, including both its mobile and online platforms (collectively referred to as the "Site", "we", or "us"). It is intended to disclose the financial relationships that Comparison411.com has with its affiliates, advertisers, sponsors, and other third parties appearing on the Site (collectively referred to as "Affiliates"). This disclosure adheres to applicable laws and regulations regarding transparency in endorsements and advertising. We have issued this disclosure because Comparison411.com earns revenue and receives other forms of compensation from Affiliates for various advertising, sponsorships, insertion orders, and promotional campaigns that are conducted on the Site. As such, there is a financial connection between some of the products or services reviewed, recommended, or mentioned on this Site and the Affiliates. Should you decide to purchase any such product or service mentioned on the Site, Comparison411.com may receive additional compensation from that purchase from the Affiliate.' />
      </div>

      <Hero />

      <div className='bg-gray-100 text-white relative overflow-hidden'>
        <BackgroundPattern className='absolute -left-48 top-3/4 max-w-md text-gray-200 z-0' />
        <BackgroundPattern className='absolute -right-48 bottom-3/4 max-w-xl text-gray-300 z-0' />

        <div className='relative'>
          <div className='max-w-7xl mx-auto space-y-40 md:space-y-52 px-4 pt-12 md:pt-8 pb-32'>

            <div className='max-w-4xl mx-auto'>
              <SectionHeading>
                We do the research, You make the decision.
              </SectionHeading>
              <div className='rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3'>
                {
                  states.map((state) => (
                    <StateCard
                      key={state.label}
                      data={state.data}
                      label={state.label}
                      Icon={state.Icon}
                    />
                  ))
                }
              </div>
            </div>

            <div>
              <SectionHeading>
                Popular Categories
              </SectionHeading>
              <div className='mt-12'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12'>
                  {sortedVerticals.map((vertical, index) => (
                    <div
                      key={vertical.title}
                      className={`bg-gray-50 rounded-lg px-6 pb-8 shadow-lg ${index === 0 ? 'col-span-1 md:row-span-2' : 'col-span-1'}`}
                    >
                      <PopularCategoriesCard icon={vertical.icon} name={vertical.title} categories={vertical.categories} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <SectionHeading>
                Popular Reviews
              </SectionHeading>

              <div className='grid grid-cols-2 md:grid-cols-4 gap-1'>
                {companies.map(({ imageUrl, siteUrl }) => (
                  <ClickableWrapper
                    key={imageUrl}
                    className='flex flex-col items-center justify-center gap-y-2 bg-gray-50 p-4 md:p-8'
                    href={siteUrl}
                  >
                    <Logo
                      alt={imageUrl}
                      src={imageUrl}
                    />

                    <BaseCta
                      IconSuffix={ArrowNarrowRightIcon}
                      label='Read review'
                    />
                  </ClickableWrapper>
                ))}
              </div>
            </div>

            <div className='text-center'>
              <SectionHeading>
                <span className='block'>Attract High-Intent Customers</span>
                <span className='block text-indigo-500'>Be there when they compare</span>
              </SectionHeading>

              <SolidCta
                href='/partner'
                label='Become a partner'
                target='_blank'
                IconPrefix={SpeakerphoneIcon}
              />
            </div>

          </div>
        </div>

      </div>

      <Footer />
    </>
  );
}

export async function getStaticProps() {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_CMS_ENDPOINT}/verticals`);
    if (!response.ok) {
      throw new Error(`Failed to fetch vertical data. Status: ${response.status}`);
    }
    const verticals = await response.json();
    return {
      props: {
        verticals,
      },
    };
  } catch (error) {
    console.error('Error fetching vertical data:', error);
    return {
      props: {
        verticals: [],
      },
    };
  }
}
